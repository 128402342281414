const currentYear = new Date().getFullYear();

export const isCustom = (hospitalId: number) => {
  if (currentYear < 2025) {
    return hospitalId === 138 || hospitalId === 1;
  } else {
    return hospitalId === 138 || hospitalId === 1 || hospitalId === 45;
  }
};

export const DIRECT_PRICE = currentYear < 2025 ? 5_500_000 : 6_000_000;
